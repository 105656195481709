import React from 'react';
import cx from 'classnames'
import MuiButton from '@material-ui/core/Button';

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(({ palette, spacing }) => ({
	root: {
		color: ({ color }) => color || palette.common.white,
		padding: ({ padding }) => padding || `${spacing(0.5)}px ${spacing(5)}px`,
		borderRadius: 30,
		backgroundColor: ({ bgColor }) => bgColor || palette.error.main,
		"&:hover": {
      backgroundColor: ({ bgColor }) => bgColor || palette.error.main,
		},
	}
}))

const Button = ({ color, size, disableElevation, variant, bgColor, padding, children, className, ...restProps }) => {
  const classes = useStyles({ color, bgColor, padding })
  return (
    <MuiButton
      size={size}
      variant={variant}
      className={cx(classes.root, className)}
      disableElevation={disableElevation}
      {...restProps}
    >{children}</MuiButton>
  )
}

Button.defaultProps = {
  size: 'small',
  variant: "contained",
  disableElevation: true,
}

export default Button
